<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<AccountNav></AccountNav>
			</div>
			<div class="admin-col whole_000 whole_fff flex_bd">
				<div class="acc_box api_box">
					<div class="container">
						<div class="acc-hd flex flex-ac flex-zBetween">
							<div class="acc-tit">{{$t('meun10_1')}}</div>
							<div class="acc-btn">
								<!-- <el-button type="primary" class="btn" @click="toLink('/recharge')">{{$t('btn1')}}
								</el-button> -->
								<el-button type="primary" class="btn" @click="toLink('/withdraw')">{{$t('btn2')}}
								</el-button>
								<!-- 	<el-button type="primary" class="btn">{{$t('btn3')}}</el-button> -->
								<el-button type="primary" class="btn" @click="toLink('/walletHistory')">
									{{$t('meun10_6')}}
								</el-button>
							</div>
						</div>
						<div class="acc-in flex flex-ac flex-zBetween">
							<div class="acc-name flex flex-ac"><span class="acc-name-text">{{$t('totalAssets')}}</span>
								<p>＄{{totalUSDT}}</p>
								<div class="acc-yue">≈{{currentInfo.symbol}}{{totalUSDT*currentInfo.rate}}</div>
							</div>
							<el-input v-model.trim="searchInput" @input="search" :placeholder="$t('placeholder')"
								prefix-icon="el-icon-search"></el-input>
						</div>
						<div class="acc-table exte-table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="coin" :label="$t('th1')">
									<template slot-scope="scope">
										<span>
											{{scope.row.coin.unit}}
										</span>
									</template>
								</el-table-column>
								<el-table-column prop="balance" :label="$t('th2')">
								</el-table-column>
								<el-table-column prop="frozenBalance" :label="$t('th3')">
								</el-table-column>
								<el-table-column prop="releaseBalance" :label="$t('th4')">
								</el-table-column>
								<el-table-column prop="operation" :label="$t('th5')" width="200">
									<template slot-scope="scope">
										<el-button size="mini" class="btn" :disabled="scope.row.coin.canRecharge != 1"
											type="primary" @click="toDeposit(scope.row.coinId)">{{$t('btn4')}}
										</el-button>
										<el-button size="mini" class="btn" :disabled="scope.row.coin.canWithdraw != 1"
											type="primary" @click="toWithdrawal(scope.row.coinId)">{{$t('btn5')}}
										</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		wallet
	} from '@/api/api/user'
	import AccountNav from '@/components/AccountNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			AccountNav,
			Foot
		},
		data() {
			return {
				searchInput: '',
				tableData: [],
				all: [],
				totalUSDT: 0,
				totalCny: 0
			};
		},
		computed: {
			currentInfo: function() {
				return this.$store.getters.current
			}
		},
		mounted() {
			this.getWallet()
		},
		methods: {
			getWallet() {
				wallet().then(res => {
					if (res.code == 0) {
						var arr = []
						res.data.forEach(item => {
							item.account = (item.balance + item.frozenBalance)*item.coin.usdRate
							return arr.push(item)
						})

						let usdtTotal = 0;
						for (let i = 0; i < arr.length; i++) {
							usdtTotal += arr[i].account
						}

						this.totalUSDT = usdtTotal.toFixed(2)

						this.tableData = res.data
						this.all = res.data
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			search(value) {
				console.log(value)
				let keyWord = value
				this.tableData = this.all.filter(item => {
					return item.coin.unit.indexOf(keyWord) != -1 && item.coin.unit.localeCompare(keyWord) != -1
				})

			},
			toDeposit(name) {
				this.$router.push({
					path: '/recharge',
					query: {
						name: name
					}
				})
			},
			toWithdrawal(name) {
				this.$router.push({
					path: '/withdraw',
					query: {
						name: name
					}
				})
			},
			toLink(url) {
				this.$router.push(url)
			}
		}
	}
</script>
