<template>
	<div class="acc_navbar">
		<div class="aside-bar">
			<el-menu :default-active="$route.path" class="aside-menu-nav acc-menu-nav" :router="true">
				<el-menu-item index="/money">
					<img src="../assets/photo/acc1.png" alt="" class="ico"><span>{{$t('meun10_1')}}</span>
				</el-menu-item>
				<el-menu-item index="/swapAssets">
					<img src="../assets/photo/acc2.png" alt="" class="ico"><span>{{$t('meun10_2')}}</span>
				</el-menu-item>
				<el-menu-item index="/coinSwapAssets">
					<img src="../assets/photo/acc3.png" alt="" class="ico"><span>{{$t('meun10_3')}}</span>
				</el-menu-item>
				<el-menu-item index="/secondAssets">
					<img src="../assets/photo/acc4.png" alt="" class="ico"><span>{{$t('meun10_4')}}</span>
				</el-menu-item>
				<el-menu-item index="/financeAssets">
					<img src="../assets/photo/acc5.png" alt="" class="ico"><span>{{$t('meun10_5')}}</span>
				</el-menu-item>
				<el-menu-item index="/walletHistory">
					<img src="../assets/photo/acc6.png" alt="" class="ico"><span>{{$t('meun10_6')}}</span>
				</el-menu-item>
			</el-menu>
		</div>
		<i class="iconfont icon-menu1" @click="drawer = true" type="primary"></i>
		<el-drawer :visible.sync="drawer" :direction="direction" size="240px">
			<div class="acc_bar">
				<el-menu :default-active="$route.path" class="aside-menu-nav acc-menu-nav" :router="true">
					<el-menu-item index="/money">
						<img src="../assets/photo/acc1.png" alt="" class="ico"><span>{{$t('meun10_1')}}</span>
					</el-menu-item>
					<el-menu-item index="/swapAssets">
						<img src="../assets/photo/acc2.png" alt="" class="ico"><span>{{$t('meun10_2')}}</span>
					</el-menu-item>
					<el-menu-item index="/coinSwapAssets">
						<img src="../assets/photo/acc3.png" alt="" class="ico"><span>{{$t('meun10_3')}}</span>
					</el-menu-item>
					<el-menu-item index="/secondAssets">
						<img src="../assets/photo/acc4.png" alt="" class="ico"><span>{{$t('meun10_4')}}</span>
					</el-menu-item>
					<el-menu-item index="/financeAssets">
						<img src="../assets/photo/acc5.png" alt="" class="ico"><span>{{$t('meun10_5')}}</span>
					</el-menu-item>
					<el-menu-item index="/walletHistory">
						<img src="../assets/photo/acc6.png" alt="" class="ico"><span>{{$t('meun10_6')}}</span>
					</el-menu-item>
				</el-menu>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	export default {
		name: 'AccountNav',
		data() {
			return {
				drawer: false,
				direction: 'ltr',
				
			};
		},
		created() {
			let isLogin = this.$store.state.isLogin
			setTimeout(()=>{
				if (isLogin == false) {
					this.$router.push('/')
				}
			},1000)
		},
		methods: {

		},
		watch: {

		}
	}
</script>
<style>
</style>
